<template>
	<div class="CouponList">
		<div class="row">
			<div class="col-lg-6" v-for="item in list" :key="item.id">
				<div class="box">
					<div class="box1">
						<h5>-{{item.amount}}%</h5>
						<p>{{ $t("Coupon.Consumo") }} {{item.minLimit}}€</p>
					</div>
					<div class="box2">
						<button @click="cate">{{ $t("Coupon.Conseguir") }}</button>
						<p>{{item.startTime}}-{{item.endTime}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Api from '../../api/request.js'

	export default {
		name: 'CouponList',
		components: {
			
		},
		data() {
			return {
				page: {
					size: 999,
					page: 1
				},
				list: [],
				dialog: {
					logistic: false
				}
			};
		},
		created() {
			this.getList();
		},
		methods: {
			cate() {
				this.$router.replace("/GoodsList?cateId=2" )
			},
			getList() {
				//this.$loading.start();
				Api.Cart.coupon({
					page: this.page.page,
					pageLimit: this.page.size,
				}).then(res => {
					this.list = res.data.data.list;
					//this.$loading.done();
				})
			},
		}
	};
</script>

<style lang="less" scoped>
	.row {
		margin: 50px 0;
		display: flex;
		flex-wrap: wrap;
		.col-lg-6 {
			flex: 0 0 50%;
			max-width: 50%;
			margin-bottom: 10px;
			.box {
				width: 50%;
				display: flex;
				padding: 0;
				.box1 {
					width: 49%;
					background-color: #fed100;
					text-align: center;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					padding: 20px 0;
					justify-content: center;
					border-right: 2px dotted #000;
					h5 {
						margin: 0;
						font-size: 24px;
						width: 100%;
					}
					p {
						margin: 0;
						padding: 0;
						font-size: 14px;
					}
				}
				.box2 {
					width: 50%;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					padding: 20px 0;
					justify-content: center;
					border: 1px solid #f2f2f2;
					button {
						width: 100px;
						height: 33px;
						margin: 4px 0;
						border-radius: 30px;
						border: 1px solid #fed100;
						font-size: 12px;
						cursor: pointer;
					}
					p {
						color: #999;
						font-size: 12px;
						margin: 0;
						padding: 0 5px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 500px) {
		.row{
			.col-lg-6 {
				flex: 0 0 100%;
				max-width: 100%;
				.box{
					width: 100%;
				}
			}
		}
	}
	
</style>
